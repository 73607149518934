const svg = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100%" viewBox="0 0 97 200" >
  <style>
  .rotate {
    transform-origin: 50% 50%;
    animation-name: loading;
		animation-delay: 1s;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
  @keyframes loading {
    0 {transform: rotate(0deg);}
    25% {transform: rotate(45deg);}
    50% {transform: rotate(90deg);}
    75% {transform: rotate(135deg);}
    100% {transform: rotate(180deg);}
  }
  </style>
  <path class="rotate" d="M 92,5 L 22,186 L 5,186 L 75,5 z" fill="black"/>
</svg>`;

const svgDataUrl = `data:image/svg+xml;base64,${btoa(svg)}`;

let menu = document.querySelector("#menu");
let main = document.querySelector("main");
let drawer = document.querySelector("#drawer");

menu.addEventListener("click", function(e) {
	drawer.classList.toggle("open");
	e.stopPropagation();
});
main.addEventListener("click", function() {
	drawer.classList.remove("open");
});
console.log('%cloading...%c ',"background: yellow;color: black; font-size: 12px", `background-image: url(${ svgDataUrl });
	padding-right: 2%;
	background-size: 100% 100%;
	background-position: 50% 50%;
  background-repeat: no-repeat;
	background-color: yellow;`);
setTimeout(()=>{console.log('.')},1050);
setTimeout(()=>{console.log('.')},2050);
setTimeout(()=>{console.log('.')},3050);
setTimeout(()=>{console.log('%c_welcome to my home_',"background: yellow;color: black;padding: 4px; border-radius: 8px; font-size: 12px")}, 4050);
